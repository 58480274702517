import { FC } from 'react';

import { useQuery } from 'react-query';
import styles from './Footer.module.scss';
import Header from '../header/Header';
import PopularCustomSlider from '@/components/ui/popular-custom-slider/PopularCustomSlider';
import { API_URL_CUSTOM_SLIDER_DEFAULT } from '@/helpers/variables';
import FooterBanner from '@/components/ui/banners/footer-banner/FooterBanner';

interface IFooter {
  isAuthActive: boolean;
  isDefaultPopularSlider?: boolean;
  setAuthActive: (isAuthActive: boolean) => void;
}

const Footer: FC<IFooter> = ({ isAuthActive, setAuthActive, isDefaultPopularSlider }) => {
  const { isLoading, error, data } = useQuery('Popular books slider data', () => fetch(`${API_URL_CUSTOM_SLIDER_DEFAULT}`).then((res) => res.json()), { enabled: isDefaultPopularSlider });

  return (
    <>
      {error || !isDefaultPopularSlider ? '' : <PopularCustomSlider popularSlider={data} />}
      <footer className={`${styles.footer}`}>
        <FooterBanner />
        <Header isAuthActive={isAuthActive} setAuthActive={setAuthActive} isBottom={true} />
      </footer>
    </>
  );
};

export default Footer;
