import { FC, PropsWithChildren, useState, useEffect } from 'react';
import Header from './header/Header';
import Footer from './footer/Footer';
import HeaderBanner from '../ui/header-banner/HeaderBanner';
import Meta from '../seo/Meta';
import { IMeta } from '../../interfaces/meta.interface';
import localFont from 'next/font/local';
import AuthPopup from './auth-popup/AuthPopup';
import StickyBanner from '../ui/banners/sticky-banner/StickyBanner';
import styles from './Layout.module.scss';

const fontArial = localFont({
  src: [
    {
      path: './../../fonts/arial.woff',
      weight: '400',
      style: 'normal',
    },
    {
      path: './../../fonts/arial-bold.woff',
      weight: '700',
      style: 'bold',
    },
  ],
});

const Layout: FC<PropsWithChildren<IMeta>> = ({ children, title, description, pageHref, canonicalHref, robotsNoFollow, cover, isDefaultPopularSlider = true }) => {
  const [isAuthActive, setAuthActive] = useState(false);

  //Закрыть попап авторизации
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setAuthActive(false);
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <Meta title={title} description={description} pageHref={pageHref} cover={cover} canonicalHref={canonicalHref} robotsNoFollow={robotsNoFollow}>
      <div className={isAuthActive ? `${styles.pageWrapper} ${styles.pageWrapper_auth}` : `${styles.pageWrapper}`}>
        <HeaderBanner />
        <main className={styles.mainContainer} style={fontArial.style}>
          <Header isAuthActive={isAuthActive} setAuthActive={setAuthActive} />
          {children}
          <Footer isAuthActive={isAuthActive} setAuthActive={setAuthActive} isDefaultPopularSlider={isDefaultPopularSlider} />
        </main>
        <StickyBanner />
        <AuthPopup isAuthActive={isAuthActive} setAuthActive={setAuthActive} />
      </div>
    </Meta>
  );
};

export default Layout;
