import { FC } from 'react';
import SectionTitle from '@/components/ui/section-title/SectionTitle';
import SlickSliderPopular from '../slick-slider/SlickSliderPopular';
import { IPopularBooksCustomData } from '@/interfaces/popularBooksCustom.interface';

interface ISectionTitle {
  popularSlider: IPopularBooksCustomData;
}

const PopularCustomSlider: FC<ISectionTitle> = ({ popularSlider }) => {
  return (
    <>
      {!!popularSlider && (
        <>
          <SectionTitle name={'Популярные книги'} />
          <SlickSliderPopular books={popularSlider} />
        </>
      )}
    </>
  );
};

export default PopularCustomSlider;
